import { CustomButton } from "@/components/customComponents";
import { handleDefaultRole } from "@/components/header/utils";
import ContainerContent from "@/pages/authCore/components/containerContent";
import AuthLayout from "@/pages/authCore/layout";
import { useGlobalStore } from "@/stores";
import { setDefaultTimezone } from "@/utils/date";
import { Image, Space } from "antd";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const VITE_PT_WEB_BASE = import.meta.env.VITE_PT_WEB_BASE;
const descClass = "text-white/[.5] text-base mb-[3px]";
const dotClass =
  "before:inline-block before:w-[5px] before:h-[5px] before:bg-[#00ADAE] before:rounded-full before:content-[''] before:align-middle before:mr-[10px]";

const NoviceGuide: FC = () => {
  const navigate = useNavigate();
  const { updateCurrentRole } = useGlobalStore((state) => state);

  useEffect(() => {
    setDefaultTimezone();
  }, []);

  useEffect(() => {
    const defaultRole = handleDefaultRole();
    // @ts-ignore
    updateCurrentRole(defaultRole);
  }, [updateCurrentRole]);

  const clickBtn = (type: string) => {
    let nextPage = "";
    let state: { fromPage: string } | null = { fromPage: "noviceGuide" };
    switch (type) {
      case "edit":
        nextPage = "/users/edit";
        break;
      case "verify":
        nextPage = "/companies/create";
        break;
      case "aiHome":
        nextPage = "";
        window.location.href = `${VITE_PT_WEB_BASE}ai/projects`;
        break;
      case "artwork":
        nextPage = "/portfolio/create";
        break;
      case "viewJobs":
        nextPage = "/enterprises/public-jobs";
        break;
      case "contactFromOwner":
        nextPage = "";
        window.open("https://calendly.com/yinhan-leylinepro/30min", "_blank");
        break;
      case "contactFromStudio":
        nextPage = "";
        window.open("https://calendly.com/qiushi-leylinepro/30min", "_blank");
        break;
      case "ptHome":
        nextPage = "";
        window.location.href = `${VITE_PT_WEB_BASE}projects`;
        break;
      default:
        nextPage = "/discover";
        state = null;
        break;
    }
    nextPage &&
      navigate(nextPage, {
        replace: true,
        state,
      });
  };

  return (
    <AuthLayout>
      <div className=" max-w-[1104px] m-auto">
        <ContainerContent
          hideLogo
          title="Congratulations!"
          description="Become part of the community to learn and collaborate with your peers."
          className="px-10 pb-7"
        >
          <Space size={20} wrap>
            <div className="p-5 text-center bg-white/[.1] rounded-xl w-[500px]">
              <Image
                className="!w-[60px] !h-[60px] mb-4"
                preview={false}
                src="images/aiArtist.png"
              />
              <div className="text-white text-xl mb-4">I’m an AI Artist</div>
              <div className="w-[208px] mb-7 whitespace-nowrap mx-auto text-left">
                <div className={`${descClass} ${dotClass}`}>
                  Content Creator
                </div>
                <div className={`${descClass} ${dotClass}`}>
                  Freelancer for AI Video Ads
                </div>
                <div className={`${descClass} ${dotClass}`}>
                  Seek Inspirations
                </div>
              </div>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                className="mr-3"
                onClick={() => clickBtn("aiHome")}
              >
                Video Genesis AI
              </CustomButton>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                className="mr-3"
                onClick={() => clickBtn("artwork")}
              >
                Showcase Artwork
              </CustomButton>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                onClick={() => clickBtn("viewJobs")}
              >
                Browse Jobs
              </CustomButton>
            </div>
            <div className="p-5 text-center bg-white/[.1] rounded-xl w-[500px]">
              <Image
                className="!w-[60px] !h-[60px] mb-4"
                preview={false}
                src="images/studio.png"
              />
              <div className="text-white text-xl mb-4">
                I’m a Business Owner
              </div>
              <div className="w-[208px] mb-8 whitespace-nowrap mx-auto text-left">
                <div className={`${descClass} ${dotClass}`}>Need Video Ads</div>
                <div className={`${descClass} ${dotClass}`}>
                  Need AI Freelancer
                </div>
                <div className={`${descClass} ${dotClass}`}>
                  Seek Inspirations
                </div>
              </div>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                className="mr-3"
                onClick={() => clickBtn("aiHome")}
              >
                Video Genesis AI
              </CustomButton>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                className="mr-3"
                onClick={() => clickBtn("contactFromOwner")}
              >
                Contact Us
              </CustomButton>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                onClick={() => clickBtn("verify")}
              >
                Post Jobs
              </CustomButton>
            </div>
            <div className="p-5 text-center bg-white/[.1] rounded-xl w-[500px]">
              <Image
                className="!w-[60px] !h-[60px] mb-4"
                preview={false}
                src="images/animationArtist.png"
              />
              <div className="text-white text-xl mb-4">
                I’m an Animation Artist
              </div>
              <div className="w-[208px] mb-8 whitespace-nowrap mx-auto text-left">
                <div className={`${descClass} ${dotClass}`}>
                  Showcase my Artwork
                </div>
                <div className={`${descClass} ${dotClass}`}>Apply Jobs</div>
                <div className={`${descClass} ${dotClass}`}>
                  Seek Inspirations
                </div>
              </div>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                className="mr-3"
                onClick={() => clickBtn("viewJobs")}
              >
                Browse Jobs
              </CustomButton>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                onClick={() => clickBtn("artwork")}
              >
                Publish Your Artwork
              </CustomButton>
            </div>
            <div className="p-5 text-center bg-white/[.1] rounded-xl w-[500px]">
              <Image
                className="!w-[60px] !h-[60px] mb-4"
                preview={false}
                src="images/animationStudio.png"
              />
              <div className="text-white text-xl mb-4">
                I’m an Animation Studio
              </div>
              <div className="w-[208px] mb-8 whitespace-nowrap mx-auto text-left">
                <div className={`${descClass} ${dotClass}`}>
                  Recruit Artists
                </div>
                <div className={`${descClass} ${dotClass}`}>
                  Outsource Projects
                </div>
                <div className={`${descClass} ${dotClass}`}>
                  Manage Productions
                </div>
              </div>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                className="mr-3"
                onClick={() => clickBtn("contactFromStudio")}
              >
                Contact Us
              </CustomButton>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                className="mr-3"
                onClick={() => clickBtn("verify")}
              >
                Post Jobs
              </CustomButton>
              <CustomButton
                shape="round"
                size="small"
                type="primary"
                onClick={() => clickBtn("ptHome")}
              >
                CG Pipeline
              </CustomButton>
            </div>
          </Space>
          <div className="text-center mt-7">
            <CustomButton
              shape="round"
              ghost
              type="primary"
              onClick={() => clickBtn("browsing")}
            >
              Exploring
            </CustomButton>
          </div>
        </ContainerContent>
      </div>
    </AuthLayout>
  );
};

export default NoviceGuide;
