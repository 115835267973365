import { ProducerTry } from "@/components/producerTool/ProducerTry";
import { getStaticImgUrl } from "@/utils/common";
import { Button } from "antd";
import { motion, useScroll, useTransform } from "framer-motion";
import React, { useState, useEffect, useRef } from "react";

export const ProducerBanner: React.FC = () => {
  const [active, setActive] = useState<number>(0);

  const bannerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: bannerRef,
    offset: ["start start", "end end"],
  });

  const scale = useTransform(scrollYProgress, [0, 1], [1, 2.5]);
  const opacity = useTransform(scrollYProgress, [0, 1], [1, 0]);
  const producerTryOpacity = useTransform(scrollYProgress, [0.7, 1], [0, 1]);
  const producerTryScale = useTransform(scrollYProgress, [0.5, 1], [0.5, 1]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setActive(1);
        }
      },
      { threshold: 0.1 },
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const timers: NodeJS.Timeout[] = [];
    if (active === 1) {
      timers.push(setTimeout(() => setActive(2), 500));
    } else if (active === 2) {
      timers.push(setTimeout(() => setActive(3), 500));
    }

    return () => timers.forEach((timer, idx) => clearTimeout(timer));
  }, [active]);

  return (
    <div ref={bannerRef} className="h-[200vh] relative bg-black1">
      <div className="sticky overflow-hidden top-0 h-screen">
        <div className="w-full h-full absolute top-0 flex flex-col items-center justify-center px-4">
          <div className="max-w-3xl mx-auto text-center py-16">
            <span
              className={`inline-block producer-banner text-2xl sm:text-3xl md:text-4xl lg:text-[2.875rem] lg:leading-snug font-extrabold text-white ${
                active >= 1 ? "active" : ""
              }`}
            >
              Borderless real-time collaboration on cloud from anywhere
            </span>
            <span
              className={`inline-block producer-banner text-lg md:text-xl lg:text-2xl text-white/60 mt-4 ${
                active >= 2 ? "active" : ""
              }`}
            >
              Unlock the full potential of creative teams
            </span>
          </div>
          <motion.div
            style={{ scale, opacity }}
            className={`relative producer-banner ${
              active >= 3 ? "active" : ""
            }`}
          >
            <img
              src={getStaticImgUrl("banner_producer.png") || ""}
              className="object-cover"
              width="100%"
              alt="banner-image"
              placeholder="blur"
            />
          </motion.div>
        </div>
        <motion.div
          style={{ opacity: producerTryOpacity, scale: producerTryScale }}
          className="w-full h-full"
        >
          <ProducerTry />
        </motion.div>
      </div>
      {/* <div className="absolute top-0 h-full w-full flex items-end justify-center">
        <Button className="fixed bottom-9 flex mx-auto items-center justify-center backdrop-blur-[6px] bg-grey5/50 border-grey6/80 rounded-[2.5rem] hover:!bg-grey5/50 hover:!border-grey6/80 p-3 pl-[1.875rem] h-auto">
          <span className="text-xl font-extrabold text-white">
            Try our CG Pipeline
          </span>
          <img
            src="/images/arrow-right-circle.svg"
            className="ml-4"
            alt="arrow-right"
          />
        </Button>
      </div> */}
    </div>
  );
};
