import { CustomButton } from "@/components/customComponents";
import MotionButton from "@/components/discover/landing/MotionButton";
import PipelineAnimationImage from "@/components/discover/landing/PipelineAnimationImage";
import PipelineProgressBar from "@/components/discover/landing/PipelineProgressBar";
import { IIdentity } from "@/interfaces";
import { useUserProfile } from "@/pages/discover/api";
import { getStaticImgUrl } from "@/utils/common";
import { ptLinkWithState } from "@/utils/helper";
import { useGetIdentity } from "@refinedev/core";
import { Image } from "antd";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const RESOURCES_BASE_URL = import.meta.env.VITE_STATIC_ASSETS_BASE_URL;

export const CGPipelineLanding: React.FC = () => {
  const ptUrl = ptLinkWithState();
  const { data: user } = useGetIdentity<IIdentity>();
  const { data } = useUserProfile(user?.id || "");
  const navigate = useNavigate();
  const navigateUrl = !data ? "/producer-tool" : ptUrl;
  const pipelineRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.2 }, // Adjust when animation triggers
    );

    if (pipelineRef.current) observer.observe(pipelineRef.current);

    return () => observer.disconnect();
  }, []);

  // Variants for staggered animation
  const containerVariants = {
    visible: {
      transition: { staggerChildren: 0.3, delayChildren: 0.2 },
    },
    hidden: {
      transition: { staggerChildren: 0.3, staggerDirection: -1 },
    },
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };
  return (
    <div className="relative">
      <Image
        preview={false}
        src={`${RESOURCES_BASE_URL}landingPage/landing_pipeline_bg.png`}
        alt="pipeline_banner"
        width="100%"
        className="object-cover"
      />
      <motion.div
        ref={pipelineRef}
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={containerVariants}
        className="flex flex-row w-full absolute bottom-[200px]"
      >
        <div className="flex flex-col pl-[15%]">
          <motion.div className="" variants={sectionVariants}>
            <PipelineProgressBar />
          </motion.div>
          <motion.div className="" variants={sectionVariants}>
            <Image
              preview={false}
              src={`${RESOURCES_BASE_URL}landingPage/landing_pipeline_text_1.png`}
              alt="pipeline_banner"
              className="object-cover"
            />
          </motion.div>
          <motion.div className="" variants={sectionVariants}>
            <Image
              preview={false}
              src={`${RESOURCES_BASE_URL}landingPage/landing_pipeline_text_2.png`}
              alt="pipeline_banner"
              className="object-cover"
            />
          </motion.div>
          <motion.div className="" variants={sectionVariants}>
            <div className="pl-8">
              <MotionButton
                text="Pipeline"
                onClickAction={() => {
                  if (navigateUrl.startsWith("http"))
                    window.location.href = navigateUrl;
                  else navigate(navigateUrl);
                }}
              />
            </div>
          </motion.div>
        </div>
        <div className="relative flex flex-1 justify-center">
          <PipelineAnimationImage />
        </div>
      </motion.div>
    </div>
  );
};

export default CGPipelineLanding;
