import {
  JobsLandingLeftQuotationMarkIcon,
  JobsLandingRightQuotationMarkIcon,
} from "@/components/customComponents";
import MotionButton from "@/components/discover/landing/MotionButton";
import { Image, Typography } from "antd";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const RESOURCES_BASE_URL = import.meta.env.VITE_STATIC_ASSETS_BASE_URL;

export const JobsLanding: React.FC = () => {
  const navigate = useNavigate();
  const jobRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.2 }, // Adjust when animation triggers
    );

    if (jobRef.current) observer.observe(jobRef.current);

    return () => observer.disconnect();
  }, []);

  // Variants for staggered animation
  const containerVariants = {
    visible: {
      transition: { staggerChildren: 0.4, delayChildren: 0.2 },
    },
    hidden: {
      transition: { staggerChildren: 0.4, staggerDirection: -1 },
    },
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div ref={jobRef} className="w-full relative">
      <motion.div
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={containerVariants}
      >
        <Image
          preview={false}
          src={`${RESOURCES_BASE_URL}landingPage/landing_job_bg.png`}
          width="100%"
          alt="landing_job_bg"
          className="object-cover"
        />
        <div className="absolute w-full bottom-[140px] flex flex-col gap-[100px] items-center">
          <div>
            <motion.div className="" variants={sectionVariants}>
              <div className="flex flex-row gap-[18px]">
                <Typography.Text className="text-white text-5xl font-medium">
                  Turbocharge Your Creative Career with
                </Typography.Text>
                <div className="mt-3">
                  <JobsLandingRightQuotationMarkIcon />
                </div>
              </div>
            </motion.div>
            <motion.div className="pl-10" variants={sectionVariants}>
              <div className="flex flex-row gap-[26px]">
                <div className="pt-11">
                  <JobsLandingLeftQuotationMarkIcon className="" />
                </div>
                <div className="flex flex-row gap-[22px]">
                  <Typography.Text className="text-[100px] font-semibold bg-gradient-to-r from-[#B991E3] via-[#5988FF] to-[#69EAFF] bg-clip-text text-transparent">
                    Top Job
                  </Typography.Text>
                  <Typography.Text className="text-white text-5xl font-medium pt-[80px]">
                    Opportunities
                  </Typography.Text>
                </div>
              </div>
            </motion.div>
          </div>
          <motion.div className="" variants={sectionVariants}>
            <MotionButton
              text="Jobs"
              onClickAction={() => navigate("/enterprises/public-jobs")}
            />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default JobsLanding;
