import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const RESOURCES_BASE_URL = import.meta.env.VITE_STATIC_ASSETS_BASE_URL;

const PipelineAnimationImage = () => {
  const [showSecond, setShowSecond] = useState(false);
  const [showThird, setShowThird] = useState(false);
  const [showFirst, setShowFirst] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowSecond(false);
          setShowThird(false);
          setShowFirst(false);

          setTimeout(() => setShowSecond(true), 300);
          setTimeout(() => setShowThird(true), 800);
          setTimeout(() => setShowFirst(true), 1400);
        }
      },
      { threshold: 0.5 },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);
  return (
    <div ref={containerRef} className="relative min-w-[480px]">
      {showSecond && (
        <motion.img
          src={`${RESOURCES_BASE_URL}landingPage/landing_pipeline_image_2.png`}
          alt="landing_pipeline_image_2"
          className="absolute top-0 left-0 z-10 object-cover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      )}
      {showThird && (
        <motion.img
          src={`${RESOURCES_BASE_URL}landingPage/landing_pipeline_image_3.png`}
          alt="landing_pipeline_image_3"
          className="absolute top-[190px] left-[64px] z-30 object-cover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      )}
      {showFirst && (
        <motion.img
          src={`${RESOURCES_BASE_URL}landingPage/landing_pipeline_image_1.png`}
          alt="landing_pipeline_image_1"
          className="absolute top-[205px] left-[172px] z-20 object-cover"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      )}
    </div>
  );
};

export default PipelineAnimationImage;
