import { CircleIcon } from "@/components/customComponents/CustomIcons";
import { Col, Row, Space } from "antd";
import { useScroll } from "framer-motion";
import React, { useRef, useEffect, useState } from "react";
import { ProducerCard } from "./ProducerCard";
import { ProducerCardTitle } from "./ProducerCardTitle";

export const ProducerCards: React.FC = () => {
  const cardList = [
    {
      id: 1,
      title: "Cloud pipeline",
      description:
        "Light, powerful, oncloud. The project management system for truely global teams.",
    },
    {
      id: 2,
      title: "Production Tracking",
      description:
        "Easily track the progress of your project. Import & export tasks in bulk and auto-assign a number of tasks. Get a 360 view of your data all the time.",
    },
    {
      id: 3,
      title: "Personnel management",
      description:
        "Hire anyone, anytime, anywhere. Set access control easily for your team. We also provide time tracking, settlement and global payroll solutions.",
    },
    {
      id: 4,
      title: "Director's review system",
      description: "Shots review and version compare made easy.",
    },
  ];

  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start start", "end end"],
  });

  const [activeIndex, setActiveIndex] = useState(0);

  const scrollToIndex = (index: number) => {
    const containerTop =
      //@ts-ignore
      container.current.getBoundingClientRect().top + window.scrollY;
    const scrollTarget =
      containerTop + index * window.innerHeight + window.innerHeight * 0.15; // Adjusting the scroll position by 15vh
    window.scrollTo({
      top: scrollTarget,
      behavior: "smooth",
    });
    setActiveIndex(index);
  };

  useEffect(() => {
    if (activeIndex > 3) setActiveIndex(3);
  }, [activeIndex]);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((latest) => {
      const totalCards = cardList.length;
      const cardHeight = 1 / totalCards;
      const currentIndex = Math.floor(latest / cardHeight);
      setActiveIndex(currentIndex);
    });

    return () => unsubscribe();
  }, [scrollYProgress]);

  return (
    <div
      ref={container}
      className="bg-grey1 flex pt-8 sm:pt-12 md:pt-20 lg:pt-32"
    >
      <div className="relative max-w-7xl mx-auto px-6">
        <Row gutter={4}>
          <Col xs={24} lg={12}>
            <div className="sticky top-[15vh] h-screen flex flex-col gap-10">
              {cardList.map((card, idx) => {
                return (
                  <ProducerCardTitle
                    key={card.id}
                    title={card.title}
                    description={card.description}
                    progress={scrollYProgress}
                    range={[idx * 0.25, 1]}
                  />
                );
              })}
            </div>
          </Col>
          <Col xs={24} lg={12} className="flex gap-5">
            <div>
              {cardList.map((card, idx) => {
                const targetScale = 1 - (cardList.length - idx) * 0.1;
                return (
                  <ProducerCard
                    key={card.id}
                    id={card.id}
                    progress={scrollYProgress}
                    range={[idx * 0.25, 1]}
                    targetScale={targetScale}
                  />
                );
              })}
            </div>
            <div className="sticky top-0 h-screen flex flex-col items-center justify-center gap-5">
              {cardList.map((card, idx) => (
                <Space
                  key={card.id}
                  onClick={() => scrollToIndex(idx)}
                  className={`flex items-center cursor-pointer ${
                    idx === activeIndex ? "h-10" : "h-2.5"
                  }`}
                >
                  {idx === activeIndex ? (
                    <div className="w-2.5 h-10 rounded-md bg-grey8 border border-grey7" />
                  ) : (
                    <CircleIcon />
                  )}
                </Space>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
