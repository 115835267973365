import {
  CGPipelineLanding,
  DiscoverLanding,
  JobsLanding,
  LandingBanner,
  VideoGenesisLanding,
} from "@/components/discover/landing";
import CursorTrail from "@/components/discover/landing/CursorTrail";
import { getStaticImgUrl } from "@/utils/common";
import React from "react";

export const LeylineLanding: React.FC = () => {
  return (
    <div
      className="flex flex-col bg-[#0C1119]"
      style={{
        cursor: `url(${getStaticImgUrl(
          "landingPage/custom_cursor_image.png",
        )}), auto`,
      }}
    >
      <CursorTrail />
      <LandingBanner />
      <VideoGenesisLanding />
      <JobsLanding />
      <DiscoverLanding />
      <CGPipelineLanding />
    </div>
  );
};

export default LeylineLanding;
