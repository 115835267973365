import { Carousel, Image } from "antd";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import styles from "../../../pages/landing/styles/landingBanner.module.css";

const RESOURCES_BASE_URL = import.meta.env.VITE_STATIC_ASSETS_BASE_URL;

export const LandingBanner: React.FC = () => {
  const bannerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.2 }, // Adjust visibility trigger threshold
    );

    if (bannerRef.current) observer.observe(bannerRef.current);

    return () => observer.disconnect();
  }, []);
  const banner_bgs: string[] = [
    `${RESOURCES_BASE_URL}landingPage/banner_1.png`,
    `${RESOURCES_BASE_URL}landingPage/banner_2.png`,
    `${RESOURCES_BASE_URL}landingPage/banner_3.png`,
  ];

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <div ref={bannerRef} className="relative h-[calc(100vh-80px)]">
      <Carousel {...settings} className={`h-full ${styles.bannerCarousel}`}>
        {banner_bgs.map((banner_bg) => (
          <div key={banner_bg}>
            <Image
              preview={false}
              src={banner_bg}
              alt={`slide-${banner_bg}`}
              width="100%"
              className="object-cover !h-[calc(100vh-80px)]"
            />
          </div>
        ))}
      </Carousel>
      <div className="absolute z-10 top-0 w-full pl-[15%] bottom-[calc(20%-110px)] flex items-end">
        <motion.image
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.8 }}
        >
          <Image
            preview={false}
            src={`${RESOURCES_BASE_URL}landingPage/banner_text.png`}
            alt="banner_text"
            className=""
          />
        </motion.image>
      </div>
    </div>
  );
};

export default LandingBanner;
