import { AllCategoriesIcon } from "@/components/customComponents";
import { getSubjectMattersAPI } from "@/pages/discover/api";
import { getStaticImgUrl } from "@/utils/common";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Col, Image, Space, Typography, message } from "antd";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import styles from "../../../pages/discover/styles/categoryList.module.css";

interface ICategoryListProps {
  filterCategory: string;
  setFilterCategory: React.Dispatch<React.SetStateAction<string>>;
  scrolled: boolean;
}

const CategoryList: React.FC<ICategoryListProps> = ({
  filterCategory,
  setFilterCategory,
  scrolled,
}) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const [subjectMatterOptions, setSubjectMatterOptions] = useState<
    {
      value: string;
      label: string;
    }[]
  >([]);

  const getSubjectMatterFunc = useCallback(async () => {
    const options = await getSubjectMattersAPI();
    setSubjectMatterOptions(
      // biome-ignore lint:
      options.map((option: any) => ({
        value: option.id,
        label: option.name,
      })),
    );
  }, []);

  useEffect(() => {
    getSubjectMatterFunc();
  }, [getSubjectMatterFunc]);
  // biome-ignore lint:
  useEffect(() => {
    const updateScrollableState = () => {
      if (scrollContainerRef.current) {
        const scrollWidth = document.querySelector(
          ".react-horizontal-scrolling-menu--scroll-container",
        )?.scrollWidth as number;
        const clientWidth = scrollContainerRef.current.clientWidth;
        console.log(scrollWidth, clientWidth);
        setIsScrollable(scrollWidth > clientWidth);
      }
    };

    updateScrollableState();

    // Add event listener for window resize
    window.addEventListener("resize", updateScrollableState);

    return () => {
      window.removeEventListener("resize", updateScrollableState);
    };
  }, [
    document.querySelector(".react-horizontal-scrolling-menu--scroll-container")
      ?.scrollWidth,
  ]);

  const LeftArrow = () => {
    const { scrollPrev, getItemById, items } = useContext(VisibilityContext);
    const [isFirstItemVisible, setIsFirstItemVisible] = useState(false);

    useEffect(() => {
      const updateVisibility = () => {
        const scrollContainer = document.querySelector(
          ".react-horizontal-scrolling-menu--scroll-container",
        );
        if (scrollContainer) {
          setIsFirstItemVisible(scrollContainer.scrollLeft === 0); // If scroll is at the leftmost position
        }
      };

      // Initial check
      updateVisibility();

      // Add scroll event listener
      const scrollContainer = document.querySelector(
        ".react-horizontal-scrolling-menu--scroll-container",
      );
      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", updateVisibility);
      }

      return () => {
        // Clean up event listener
        const scrollContainer = document.querySelector(
          ".react-horizontal-scrolling-menu--scroll-container",
        );
        if (scrollContainer) {
          scrollContainer.removeEventListener("scroll", updateVisibility);
        }
      };
    }, []);

    return (
      <Space
        onClick={() => !isFirstItemVisible && scrollPrev()}
        className={`left-arrow z-10 cursor-pointer rounded-full w-[34px] h-[34px] flex justify-center items-center ${
          isFirstItemVisible
            ? "bg-[#FFFFFF]/40"
            : "cursor-pointer bg-white hover:bg-[#FFFFFF]/80"
        }`}
      >
        <LeftOutlined />
      </Space>
    );
  };

  const RightArrow = () => {
    const { scrollNext, getItemById, items } = useContext(VisibilityContext);
    const [isLastItemVisible, setIsLastItemVisible] = useState(false);

    useEffect(() => {
      const updateVisibility = () => {
        const scrollContainer = document.querySelector(
          ".react-horizontal-scrolling-menu--scroll-container",
        );
        if (scrollContainer) {
          const scrollWidth = scrollContainer.scrollWidth;
          const clientWidth = scrollContainer.clientWidth;
          setIsLastItemVisible(
            scrollContainer.scrollLeft + clientWidth >= scrollWidth,
          ); // If scroll is at the rightmost position
        }
      };

      // Initial check
      updateVisibility();

      // Add scroll event listener
      const scrollContainer = document.querySelector(
        ".react-horizontal-scrolling-menu--scroll-container",
      );
      if (scrollContainer) {
        scrollContainer.addEventListener("scroll", updateVisibility);
      }

      return () => {
        // Clean up event listener
        const scrollContainer = document.querySelector(
          ".react-horizontal-scrolling-menu--scroll-container",
        );
        if (scrollContainer) {
          scrollContainer.removeEventListener("scroll", updateVisibility);
        }
      };
    }, []);
    return (
      <Space
        onClick={() => !isLastItemVisible && scrollNext()}
        className={`right-arrow z-10 cursor-pointer rounded-full w-[34px] h-[34px] justify-center items-center ${
          isLastItemVisible
            ? "bg-[#FFFFFF]/40"
            : "cursor-pointer bg-white hover:bg-[#FFFFFF]/80"
        }`}
      >
        <RightOutlined />
      </Space>
    );
  };

  return (
    <Col
      className={`fixed flex flex-row w-full mx-auto pt-6 mb-2 pb-6 gap-5 px-6 bg-[#1D1F22] z-50 ${
        scrolled ? "shadow-[0px_4px_12px_0px_rgb(0_0_0_/50%)]" : ""
      }`}
    >
      <Space
        onClick={() => setFilterCategory("All")}
        className="flex flex-row gap-4 p-2.5 items-center justify-center rounded-[12px] bg-linearGradient3 min-w-[200px] cursor-pointer hover:opacity-80"
      >
        <div className="h-10">
          <AllCategoriesIcon />
        </div>
        <Typography.Text className="text-white text-lg font-semibold">
          All Categories
        </Typography.Text>
      </Space>
      <div
        className={`flex-1 relative overflow-hidden ${styles.categoryList}`}
        ref={scrollContainerRef}
      >
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          scrollContainerClassName="gap-6 px-6"
        >
          {subjectMatterOptions.map((subjectMatter) => (
            <Space
              key={subjectMatter.value}
              onClick={() => setFilterCategory(subjectMatter.value)}
              className={`w-max cursor-pointer flex flex-row gap-4 p-2.5 items-center justify-center rounded-[12px] border-[1px] border-solid border-[rgba(153,153,153,0.40)] hover:bg-[rgba(153,153,153,0.40)] ${
                subjectMatter.value === filterCategory
                  ? "bg-[rgba(153,153,153,0.20)]"
                  : ""
              }`}
            >
              <Image
                preview={false}
                src={getStaticImgUrl(`${subjectMatter.label}.png`) || ""}
                alt="category"
                width={70}
                height={40}
              />
              <Typography.Text className="text-white text-lg font-semibold">
                {subjectMatter.label}
              </Typography.Text>
            </Space>
          ))}
        </ScrollMenu>
      </div>
    </Col>
  );
};

export default CategoryList;
