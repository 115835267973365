import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

const CursorTrail: React.FC = () => {
  const [particles, setParticles] = useState<
    { x: number; y: number; id: number }[]
  >([]);

  useEffect(() => {
    const moveCursor = (e: MouseEvent) => {
      // Add new particles
      setParticles((prev) => [
        ...prev,
        { x: e.clientX, y: e.clientY, id: Date.now() },
      ]);

      // Remove old particles after a delay
      setTimeout(() => {
        setParticles((prev) => prev.slice(1));
      }, 500);
    };

    window.addEventListener("mousemove", moveCursor);
    return () => window.removeEventListener("mousemove", moveCursor);
  }, []);

  return (
    <>
      {particles.map((p) => (
        <motion.div
          key={p.id}
          className="cursor_particle"
          initial={{ opacity: 1, scale: 1 }}
          animate={{ opacity: 0, scale: 5 }}
          transition={{ duration: 0.5 }}
          style={{ left: p.x, top: p.y }}
        />
      ))}
    </>
  );
};

export default CursorTrail;
