import { getStaticImgUrl } from "@/utils/common";
import { ptLinkWithState } from "@/utils/helper";
import { Button } from "antd";
import React from "react";
import { redirect } from "react-router-dom";

export const ProducerTry: React.FC = () => {
  const ptUrl = ptLinkWithState();
  return (
    <div className="relative h-full">
      {/* <img
        src="/images/banner_producer2.png"
        className="hidden md:block"
        width="100%"
        alt="producer try"
      />
      <div className="hidden md:block absolute bg-black/65 top-0 left-0 w-full h-full" /> */}
      <div
        className="relative bg-cover bg-no-repeat w-full h-full pt-40"
        style={{
          backgroundImage: `url(${getStaticImgUrl("banner_producer2.png")}`,
        }}
      >
        <div className="absolute bg-black/65 top-0 left-0 w-full h-full" />
        <div className="relative flex flex-col gap-10 max-w-7xl mx-auto px-6">
          <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-white font-extrabold">
            Try our CG Pipeline
          </span>
          <span className="text-lg md:text-xl lg:text-2xl text-white font-semibold">
            Unlock the full potential of creative teams
          </span>
          <Button
            href={ptUrl}
            className="flex items-center justify-center backdrop-blur-[6px] bg-grey5/50 border-grey6/80 rounded-[2.5rem] hover:!bg-grey5/50 hover:!border-grey6/80 p-3 pl-[1.875rem] h-auto w-fit"
          >
            <span className="text-base md:text-lg lg:text-xl font-extrabold text-white">
              Try our CG Pipeline
            </span>
            <img
              src={getStaticImgUrl("arrow-right-circle.svg") || ""}
              className="ml-4"
              alt="arrow-right"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
