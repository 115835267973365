import { Space } from "antd";
import { useEffect, useState } from "react";

const PipelineProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => (prev >= 99 ? 0 : prev + 1)); // Loop back to 0
    }, 80); // Adjust speed here

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative flex flex-row gap-10 items-center pl-8">
      {/* Progress Bar Background */}
      <div className="w-[320px] h-6 bg-gray-800 rounded-full">
        {/* Filled Progress */}
        <div
          className="h-6 rounded-full bg-gradient-to-r from-[#00FEFF] to-cyan-600 transition-all duration-50"
          style={{ width: `${progress}%` }}
        />
      </div>

      {/* Percentage Text */}
      <span className="pb-4 text-white text-9xl !font-jost w-auto text-right">
        {progress}
        <span className="text-4xl"> %</span>
      </span>
    </div>
  );
};

export default PipelineProgressBar;
