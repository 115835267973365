import { CustomDropDown } from "@/components/customComponents/CustomDropDown";
import { IIdentity } from "@/interfaces";
import { useUserProfile } from "@/pages/discover/api";
import { useGlobalStore } from "@/stores";
import { checkIsInPersonalCenter } from "@/utils/common";
import { ptLinkWithState } from "@/utils/helper";
import { useGetIdentity, useMenu, useParsed } from "@refinedev/core";
import { Button, Dropdown, Typography } from "antd";
import { FC, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
const VITE_PT_WEB_BASE = import.meta.env.VITE_PT_WEB_BASE;

const NavigationList: FC = () => {
  const ptUrl = ptLinkWithState();
  const aiProjectPageUrl = `${VITE_PT_WEB_BASE}ai/projects`;
  const { menuItems } = useMenu({});
  const { pathname } = useParsed();
  const { updateMenuItems } = useGlobalStore((state) => state);
  const { data: user } = useGetIdentity<IIdentity>();
  const { data } = useUserProfile(user?.id || "");
  const navigate = useNavigate();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    updateMenuItems(menuItems);
  }, []);

  const isInPersonalCenter = useMemo(
    () => checkIsInPersonalCenter(pathname, menuItems),
    [pathname, menuItems],
  );

  const navigationBar = useMemo(() => {
    return [
      {
        name: "VideoGenesis",
        path: "/ai",
        linkPath: `${!data ? "/ai" : aiProjectPageUrl}`,
      },
      {
        name: "Jobs",
        path: "/jobs",
        linkPath: "/enterprises/public-jobs",
      },
      { name: "Discover", path: "/discover", linkPath: "/discover" },
      {
        name: "CG Pipeline",
        path: "/producer-tool",
        linkPath: `${!data ? "/producer-tool" : ptUrl}`,
      },
    ];
  }, [data, ptUrl, aiProjectPageUrl]);

  const HomeItems = useMemo(() => {
    return navigationBar.map((nav) => ({
      key: nav.name,
      label: (
        <Link
          to={nav.linkPath}
          className="font-medium !text-[#999] text-base hover:!text-white"
        >
          {nav.name}
        </Link>
      ),
    }));
  }, [navigationBar]);

  return isInPersonalCenter ? (
    <>
      <CustomDropDown arrow menu={{ items: HomeItems }}>
        <Button
          type="link"
          className="opacity-[78] font-medium text-[#999] text-base hover:!text-white"
        >
          Home
        </Button>
      </CustomDropDown>
      <Button
        type="link"
        className="opacity-[78] font-medium text-white text-base hover:!text-white"
      >
        Personal Center
      </Button>
    </>
  ) : (
    <>
      {navigationBar.map((item, index) => (
        <Button
          type="link"
          key={item.name}
          className="opacity-[78] font-medium"
          onClick={() => {
            if (item.linkPath.startsWith("http"))
              window.location.href = item.linkPath;
            else navigate(item.linkPath);
          }}
        >
          <Typography.Text
            className={`${
              pathname === item.linkPath.replace("/mktp", "") ||
              pathname === item.path
                ? "text-white"
                : "text-[#999]"
            } text-base hover:text-white`}
          >
            {" "}
            {item.name}
          </Typography.Text>
        </Button>
      ))}
    </>
  );
};

export default NavigationList;
