import MotionButton from "@/components/discover/landing/MotionButton";
import { IIdentity } from "@/interfaces";
import { useUserProfile } from "@/pages/discover/api";
import { useGetIdentity } from "@refinedev/core";
import { Image } from "antd";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const RESOURCES_BASE_URL = import.meta.env.VITE_STATIC_ASSETS_BASE_URL;
const VITE_PT_WEB_BASE = import.meta.env.VITE_PT_WEB_BASE;

export const VideoGenesisLanding: React.FC = () => {
  const navigate = useNavigate();
  const aiProjectPageUrl = `${VITE_PT_WEB_BASE}ai/projects`;
  const { data: user } = useGetIdentity<IIdentity>();
  const { data } = useUserProfile(user?.id || "");
  const navigateUrl = !data ? "/ai" : aiProjectPageUrl;
  const [isInView, setIsInView] = useState(false);
  const genesisRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true); // Start animation when component enters the viewport
        } else {
          setIsInView(false); // Reset animation when component is out of view
        }
      },
      { threshold: 0.1 }, // Trigger when at least 10% of the component is visible
    );

    if (genesisRef.current) {
      observer.observe(genesisRef.current);
    }

    return () => {
      if (genesisRef.current) {
        observer.unobserve(genesisRef.current);
      }
    };
  }, []);

  const containerVariants = {
    visible: {
      transition: { staggerChildren: 0.4, delayChildren: 0.2 },
    },
    hidden: {
      transition: { staggerChildren: 0.4, staggerDirection: -1 },
    },
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div ref={genesisRef} className="w-full relative">
      <motion.div
        className="w-full"
        initial="hidden"
        animate={isInView ? "visible" : "hidden"}
        variants={containerVariants}
      >
        <Image
          preview={false}
          src={`${RESOURCES_BASE_URL}landingPage/landing_genesis_bg_2.png`}
          alt="landing_genesis_bg_2"
          width="100%"
          className="object-cover"
        />

        <motion.div
          className="absolute top-0 left-0 w-full h-full"
          animate={{
            clipPath: `inset(0 ${isInView ? 100 : 0}% 0 0)`, // Gradually reveal the left side of Image 1
          }}
          transition={{ duration: 3 }}
        >
          <Image
            className="w-full h-full object-cover"
            width="100%"
            src={`${RESOURCES_BASE_URL}landingPage/landing_genesis_bg_1.png`}
            alt="landing_genesis_bg_1"
            preview={false}
          />
          <div className="bg-[#000000]/50 w-full h-full absolute top-0" />
        </motion.div>

        <motion.div
          className="absolute left-[15%] bottom-[8%] flex flex-col"
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }} // Fade in text when in view
          transition={{ duration: 3 }} // Text fades in slowly
        >
          <div>
            <motion.img
              src={`${RESOURCES_BASE_URL}landingPage/landing_genesis_text.png`}
              alt="genesis_text"
              variants={sectionVariants}
            />
          </div>
          <motion.div className="mt-10" variants={sectionVariants}>
            <MotionButton
              text="Video Genesis"
              onClickAction={() => {
                if (navigateUrl.startsWith("http"))
                  window.location.href = navigateUrl;
                else navigate(navigateUrl);
              }}
            />
          </motion.div>
          <div className="flex flex-row gap-4 mt-[78px]">
            <motion.img
              src={`${RESOURCES_BASE_URL}landingPage/landing_genesis_item_1.png`}
              alt="genesis_text"
              variants={sectionVariants}
            />
            <motion.img
              src={`${RESOURCES_BASE_URL}landingPage/landing_genesis_item_2.png`}
              alt="genesis_text"
              variants={sectionVariants}
            />
            <motion.img
              src={`${RESOURCES_BASE_URL}landingPage/landing_genesis_item_3.png`}
              alt="genesis_text"
              variants={sectionVariants}
            />
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default VideoGenesisLanding;
