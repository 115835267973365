import { AllCategoriesIcon, CustomButton } from "@/components/customComponents";
import MotionButton from "@/components/discover/landing/MotionButton";
import { LeftOutlined } from "@ant-design/icons";
import { Image, Space, Typography } from "antd";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const RESOURCES_BASE_URL = import.meta.env.VITE_STATIC_ASSETS_BASE_URL;

export const DiscoverLanding: React.FC = () => {
  const navigate = useNavigate();
  const discoverRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  const main_images = () => {
    const rows = [];
    for (let i = 0; i < 18; i += 6) {
      rows.push(
        <div className="w-full flex flex-row gap-2 2xl:gap-4 lg:gap-3" key={i}>
          {items.slice(i, i + 6).map((item, index) => (
            // biome-ignore lint:
            <div
              key={item}
              onClick={() => navigate("/discover")}
              className="w-full cursor-pointer transition-transform duration-200 ease-in-out transform hover:scale-[1.3] hover:z-10 hover:border-solid hover:border-[4px] hover:border-[#00FEFF] hover:shadow-[0px_0px_40px_0px_#00FEFF] hover:rounded-[20px] overflow-hidden"
            >
              <Image
                preview={false}
                src={`${RESOURCES_BASE_URL}landingPage/landing_discover_item_${item}.png`}
                alt="pipeline_banner"
                className="object-cover"
              />
            </div>
          ))}
        </div>,
      );
    }

    return <div className="flex flex-col gap-4">{rows}</div>;
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.2 }, // Adjust when animation triggers
    );

    if (discoverRef.current) observer.observe(discoverRef.current);

    return () => observer.disconnect();
  }, []);

  // Variants for staggered animation
  const containerVariants = {
    visible: {
      transition: { staggerChildren: 0.4, delayChildren: 0.2 },
    },
    hidden: {
      transition: { staggerChildren: 0.4, staggerDirection: -1 },
    },
  };

  const sectionVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  return (
    <div ref={discoverRef} className="relative w-full pb-[180px]">
      <motion.div
        className="w-full relative pt-[150px]"
        initial="hidden"
        animate={isVisible ? "visible" : "hidden"}
        variants={containerVariants}
      >
        <Image
          preview={false}
          src={`${RESOURCES_BASE_URL}landingPage/landing_discover_effect.png`}
          width="100%"
          alt="landing_discover_effect"
          className="object-cover"
        />
        <div className="absolute top-[200px] z-10 w-full flex flex-col gap-[70px]">
          <motion.div
            className="w-full flex justify-center relative"
            variants={sectionVariants}
          >
            <div className="w-[90%] flex flex-col gap-10">
              <div className="w-full flex flex-row items-center gap-5">
                <div className="min-w-[200px] h-[60px] gap-4 p-2.5 flex flex-row items-center justify-center rounded-[12px] bg-linearGradient3">
                  <div className="h-10">
                    <AllCategoriesIcon />
                  </div>
                  <Typography.Text className="text-white text-lg font-semibold">
                    All Categories
                  </Typography.Text>
                </div>
                <div className="h-[60px] relative w-[calc(100%-224px)] overflow-hidden">
                  <div className="flex items-center">
                    <Space className="absolute z-10 top-[14px] cursor-pointer bg-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                      <LeftOutlined />
                    </Space>
                  </div>
                  <div className="ml-[60px] h-[60px] w-max">
                    <Image
                      preview={false}
                      src={`${RESOURCES_BASE_URL}landingPage/landing_discover_categorybar.png`}
                      alt="pipeline_banner"
                      height="60px"
                      className=""
                    />
                  </div>
                  <div className="absolute z-10 -top-[10px] h-[60px] right-0">
                    <Image
                      preview={false}
                      src={`${RESOURCES_BASE_URL}landingPage/landing_discover_category_right.png`}
                      alt="pipeline_banner"
                      className="object-cover"
                    />
                  </div>
                </div>
              </div>
              {main_images()}
            </div>
          </motion.div>
          <motion.div
            className="w-full flex justify-center relative"
            variants={sectionVariants}
          >
            <Image
              preview={false}
              src={`${RESOURCES_BASE_URL}landingPage/landing_discover_text.png`}
              alt="pipeline_banner"
              className="object-cover"
            />
            <Image
              preview={false}
              src={`${RESOURCES_BASE_URL}landingPage/landing_discover_star.png`}
              alt="pipeline_banner"
              className="object-cover"
            />
          </motion.div>
          <motion.div
            className="w-full flex justify-center relative"
            variants={sectionVariants}
          >
            <MotionButton
              text="Discover"
              onClickAction={() => navigate("/discover")}
            />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default DiscoverLanding;
