import { ArrowRightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";

interface IMotionButtonProps {
  onClickAction?: () => void;
  text: string;
}

const MotionButton: React.FC<IMotionButtonProps> = ({
  onClickAction,
  text,
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(buttonRef, { amount: 0.2, once: false });
  const [isTextVisible, setIsTextVisible] = useState(false);

  useEffect(() => {
    if (isInView) {
      const timeout = setTimeout(() => setIsTextVisible(true), 1000);
      return () => clearTimeout(timeout);
    }
    setIsTextVisible(isInView);
  }, [isInView]);

  return (
    <motion.div
      ref={buttonRef}
      onClick={onClickAction}
      initial={{ opacity: 0, scale: 0 }}
      animate={{
        opacity: isInView ? 1 : 0,
        scale: isInView ? 1 : 0,
        transition: { duration: 0.5, ease: "easeOut", delay: 0.5 },
      }}
      className="bg-black cursor-pointer h-20 w-fit bg-opacity-50 border-solid border-[2px] rounded-full border-white border-opacity-80 backdrop-blur-lg flex flex-row items-center px-3 py-3 shadow-[0px_4px_60px_#000] hover:shadow-[0px_0px_10px_12px_#FFFFFF/70]"
    >
      <motion.div
        initial={{ opacity: 0, width: 0 }}
        animate={{
          opacity: isTextVisible ? 1 : 0,
          paddingLeft: isTextVisible ? "38px" : "0px",
          paddingRight: isTextVisible ? "40px" : "0px",
          width: isTextVisible ? "fit-content" : "0px",
          transition: { duration: 0.5, ease: "linear" },
        }}
        className="text-nowrap overflow-hidden"
      >
        <Typography.Text className="text-white text-4xl font-medium">
          {text}
        </Typography.Text>
      </motion.div>
      <div className="text-[#333333] bg-[#87D9DC] w-14 h-14 rounded-full flex justify-center items-center">
        <ArrowRightOutlined className="text-xl" />
      </div>
    </motion.div>
  );
};

export default MotionButton;
